import React from "react";

const NotFound = () => {
  return (
    <p className="center flex al-ctr">
      <h1 className="layout_404"> 404</h1>
      <h2 className="layout_nf">This page could not be found.</h2>
    </p>
  );
};
export default NotFound;
