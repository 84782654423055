import React from "react";

function ChevronForwardOutline({ stroke, strokeWidth, ...rest }) {
  return (
    <svg
      // xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 512 512"
      {...rest}
    >
      <path
        fill="none"
        stroke={stroke || "#000"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth || "48"}
        d="M184 112L328 256 184 400"
      ></path>
    </svg>
  );
}

export default ChevronForwardOutline;
