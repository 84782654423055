import React from "react";

export default function ChevronDownOutline(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 512 512" {...props}>
      <polyline
        points="112 184 256 328 400 184"
        style={{
          fill: "none",
          stroke: props.stroke || "#000",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "48px",
        }}
      />
    </svg>
  );
}
