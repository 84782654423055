import React from "react";
function User(props) {
  return (
    <svg viewBox="0 0 456.368 456.368" width="20" height="20" {...props}>
      <path d="M324.194 220.526c-6.172 7.772-13.106 14.947-21.07 21.423 45.459 26.076 76.149 75.1 76.149 131.158 0 30.29-66.367 54.018-151.09 54.018s-151.09-23.728-151.09-54.018c0-56.058 30.69-105.082 76.149-131.158-7.963-6.476-14.897-13.65-21.07-21.423-50.624 31.969-84.322 88.41-84.322 152.581 0 19.439 10.644 46.53 61.355 65.201 31.632 11.647 73.886 18.06 118.979 18.06s87.347-6.413 118.979-18.06c50.71-18.671 61.355-45.762 61.355-65.201-.002-64.171-33.7-120.612-84.324-152.581z" />
      <path d="M228.182 239.795c56.833 0 100.597-54.936 100.597-119.897C328.779 54.907 284.993 0 228.182 0c-56.833 0-100.597 54.936-100.597 119.897 0 64.991 43.787 119.898 100.597 119.898zm0-210.552c39.344 0 71.354 40.667 71.354 90.654s-32.01 90.654-71.354 90.654-71.354-40.667-71.354-90.654 32.01-90.654 71.354-90.654z" />
    </svg>
  );
}

export default User;
