import decodeJwt from "jwt-decode";

export default function decodeToken(token, claims) {
  if (token) {
    try {
      const decodedClaims = decodeJwt(token);
      return claims.reduce((res, c) => {
        return {
          ...res,
          [c.name]: decodedClaims[c.base ? `${c.base}/${c.name}` : c.name],
        };
      }, {});
    } catch (e) {
      console.error("Token decoding error!");
    }
  } else
    return claims.reduce((res, c) => {
      return {
        ...res,
        [c.name]: "",
      };
    }, {});
}
