import React, { createContext, useState } from "react";
import devLogger from "../devLogger";
import { delApi } from "../delApi";

export const AuthContext = createContext();

function AuthProvider({ children }) {
  const [auth, setAuth] = useState({
    token: localStorage.getItem("access_token"),
    refreshToken: localStorage.getItem("refresh_token"),
    username: localStorage.getItem("uname") || "",
  });

  async function logout(sessionExpired) {
    const payload = {
      AccessToken: localStorage.getItem("refresh_token"),
      RealmName: localStorage.getItem("realmName"),
      UserId: localStorage.getItem("sub"),
      CompanyId: localStorage.getItem("companyId"),
    };
    if (payload.AccessToken && payload.RealmName && !sessionExpired) {
      const res = delApi(
        "/admin/api/authentication/logout",
        "POST",
        payload,
        null,
        null,
        { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
      );
    }
    const link = localStorage.getItem("link");

    if (link) {
      localStorage.clear();
      localStorage.setItem("link", link);
    } else {
      localStorage.clear();
      sessionStorage.clear();
    }
    setAuth({ token: null, refreshToken: null, username: "" });
  }

  return (
    <AuthContext.Provider value={{ auth, setAuth, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
