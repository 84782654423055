import React from "react";

function Lock(props) {
  const style = `.st0 {
    fill:none;
    stroke:#ccc;
    strokeWidth:2;
    strokeLinecap:round;
    strokeLinejoin:round;
    strokeMiterlimit:10
  }`;
  return (
    <div
      style={{ border: "2px solid #ccc", borderRadius: "50%", padding: "5px" }}
    >
      <svg
        id="Icons"
        version="1.1"
        viewBox="0 0 32 32"
        xmlSpace="preserve"
        {...props}
      >
        <style>{style}</style>
        <circle className="st0" cx="16" cy="21" r="8" />
        <path className="st0" d="M10 15.3V9c0-3.3 2.7-6 6-6s6 2.7 6 6v6.3" />
        <circle className="st0" cx="16" cy="20" r="2" />
        <path className="st0" d="M16 25v-3" />
      </svg>
    </div>
  );
}

export default Lock;
