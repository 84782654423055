import React from "react";
import AuthProvider from "./lib/contexts/AuthContext";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import ApplicationProvider from "./lib/contexts/ApplicationContext";

export default function Root() {
  return (
    <AuthProvider>
      <ApplicationProvider>
        <Router>
          <App />
        </Router>
      </ApplicationProvider>
    </AuthProvider>
  );
}
