import React from "react";

function CloseOutline(props) {
  return (
    <svg
      width={20}
      height={20}
      stroke="currentColor"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={32}
        d="M368 368L144 144M368 144L144 368"
      />
    </svg>
  );
}

export default CloseOutline;
