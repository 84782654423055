import "./login.css";
import LockIcon from "../../icons/lock";
import React, { useContext, useEffect, useState } from "react";
import { delApi } from "../../lib/delApi";
import useAuth from "../../lib/hooks/useAuth";
import { useHistory, useLocation } from "react-router-dom";
import { ApplicationContext } from "../../lib/contexts/ApplicationContext";
import decodeToken from "../../lib/decodeToken";
import { Notyf } from "notyf";
import {
  EMPTY_VALUES,
  UNAUTHORIZED_STATUS_CODE,
} from "../../lib/utils/layoutConstants";
import { Tabs } from "antd";
const { TabPane } = Tabs;
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OTPSection from "./OTPSection";

const LOGIN = "LOGIN";
const FORGOT_PASSWORD = "FORGOT PASSWORD";
const OTP = "OTP";
const PASSWORD = "PASSWORD";

function Login() {
  const { setAuth } = useAuth();
  const [notyf, setNotyf] = useState(null);
  const { setApplication } = useContext(ApplicationContext);
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [password, setPassword] = useState("");
  const [email_id, setEmailId] = useState("");
  const [errors, setErrors] = useState({});
  const [sectionToShow, setSectionToShow] = useState(LOGIN);
  const [form_title, setFormTitle] = useState("Login");
  const [errors_forgotPassword, setErrorsForgotPassword] = useState("");
  const [isWrongAttempt, setIsWrongAttempt] = useState(false);
  const [userNameType, setUserNameType] = useState("Email");
  const [loginType, setLoginType] = useState(PASSWORD);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const { pathname } = useLocation();

  //const { data: companyDetails } = useSWR("/admin/api/company/avt/logo");

  const [companyDetails, setCompanyDetails] = useState(null);

  function onChangeEmail(value) {
    const pattern = /^\w([\w\-\.]*)\w@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setEmailId(value);
    let _errors = "";

    if (value === "") {
      _errors = "Username is required";
    }
    // else if (value) {
    //   if (!value.toLowerCase().match(pattern)) {
    //     _errors = "Invalid Email-id";
    //   }
    else {
      _errors = "";
    }
    // }
    setErrorsForgotPassword(_errors);
  }

  function onChange({ target: { name, value } }) {
    name === "username" ? setUsername(value) : setPassword(value);
    let _errors = { ...errors };
    if (value === "") {
      setErrors((s) => ({
        ...s,
        [name]: `${name === "username" ? "Username" : "Password"} is required`,
      }));
    } else {
      delete _errors[name];
      setErrors(_errors);
    }
  }

  useEffect(() => {
    if (pathname !== "/") {
      history.replace("/");
    }
  }, []);

  useEffect(() => {
    const notyf = new Notyf({
      duration: 5000,
      dismissible: true,
      position: { x: "right", y: "top" },
      types: [
        {
          type: "warning",
          background: "#ffe827",
          className: "del_warning-toast-wrapper",
        },
      ],
    });
    setNotyf(notyf);
  }, []);

  useEffect(() => {
    async function getCompanyDetails() {
      const res = await delApi("/admin/api/company/logo", "POST", {
        cname:
          location.hostname === "localhost"
            ? "indianrailwaydemonew.delpheon.io"
            : location.host,
      });
      //"avt.delpheon.io"
      if (res) {
        setCompanyDetails(res);
        localStorage.setItem("companyName", res.CompanyName);
        localStorage.setItem("realmName", res.RealmName);
        localStorage.setItem("companyId", res.Id);
        localStorage.setItem("logo", res.Logo);
      }
    }
    getCompanyDetails();
  }, []);

  function validateForgotPassword() {
    if (sectionToShow) {
      if (email_id === "") {
        setErrorsForgotPassword("Username is required");
        return false;
      }
      return true;
    }
  }

  function validateData(validateFor) {
    let _errors = { ...errors };
    if (userNameType === "Email") {
      delete _errors["phoneNumber"];
      if (username === "") {
        _errors.username = "Username is required";
      }
    } else {
      delete _errors["username"];
      if (
        EMPTY_VALUES.includes(phoneNumber) ||
        phoneNumber === selectedCountry.dialCode
      ) {
        _errors.phoneNumber = "Mobile is required";
      } else {
        const mobileWithCountryCodeLength =
          selectedCountry?.format.split(".").length - 1 || 0;
        const isValid =
          phoneNumber && phoneNumber.length === mobileWithCountryCodeLength;
        if (!isValid) {
          _errors.phoneNumber = "Invalid mobile number";
        }
      }
    }
    if (validateFor === OTP) {
      delete _errors.password;
    } else {
      if (password === "") {
        _errors.password = "Password is required";
      }
    }
    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function onForgotPassword(e) {
    e.preventDefault();
    const apiLink = "/admin/api/authentication/forgotpassword";
    if (validateForgotPassword()) {
      const payload = {
        CompanyId: localStorage.getItem("companyId"),
        RealmName: localStorage.getItem("realmName"),
        UserName: email_id,
      };
      console.log(payload);
      const res = await delApi(
        apiLink,
        "POST",
        payload,
        `The password has been sent to your email. If you don't receive a recovery email, please verify that the entered email is valid.`
      );
      res && handleBackToLogin();
    }
  }

  const showOTPSection = async () => {
    setIsLoading(true);
    const success = await generateOTP();
    if (success) {
      setSectionToShow(OTP);
    }
    setIsLoading(false);
  };

  const generateOTP = async () => {
    if (validateData(OTP)) {
      const payload = {
        UserName: userNameType === "Email" ? username : "+" + phoneNumber,
        CompanyName: companyDetails?.RealmName,
      };
      const resp = await delApi(
        "/admin/api/authentication/generateotp",
        "POST",
        payload,
        "OTP sent successfully",
        null,
        null
      );
      if (resp) {
        return true;
      }
    }
  };

  async function onLogin(e, otp) {
    e.preventDefault();
    setIsLoading(true);
    if (validateData()) {
      const payload = {
        UserName: userNameType === "Email" ? username : "+" + phoneNumber,
        ...(sectionToShow === LOGIN ? { Password: password } : { otp }),
        CompanyName: companyDetails?.RealmName,
      };
      const res = await delApi(
        "/admin/api/authentication/login",
        "POST",
        payload,
        null,
        null,
        null,
        (error) => {
          try {
            if (error.status === UNAUTHORIZED_STATUS_CODE) {
              setIsWrongAttempt(true);
            }
          } catch {}
        }
      );
      if (res) {
        localStorage.setItem("access_token", res.access_token);
        localStorage.setItem("refresh_token", res.refresh_token);
        localStorage.setItem("uname", res.username);
        localStorage.setItem("mobilenumber", res.mobilenumber || "");
        localStorage.setItem("userType", res.usertype || "");
        localStorage.setItem("IncludeDelpheonLogo", res.IncludeDelpheonLogo);
        const applicationList = JSON.stringify(
          res.applicationmapping?.map((app) => ({
            ApplicationId: app.ApplicationId,
            ApplicationName: app.ApplicationName,
            Nodes: app.Nodes,
            RoleName: app.RoleName,
            Screens: app.CustomAppConfig.Screens,
            Features: app.CustomAppConfig.Features,
            IsOldApplication: app.IsOldApplication,
            CustomAppConfig: {
              Enabled: app.CustomAppConfig.Enabled,
            },
            DefaultApplication: {
              ...app.DefaultApplication,
            },
            SupersetApplication: {
              ...app.SupersetApplication,
            },
          }))
        );
        localStorage.setItem("applicationList", applicationList || "[]");
        const { sub } = decodeToken(res.access_token, [{ name: "sub" }]);
        localStorage.setItem("sub", sub);
        setApplication((s) => ({
          ...s,
          userType: res.usertype || "",
          applicationList: applicationList || "[]",
        }));
        setAuth({
          token: res.access_token,
          refreshToken: res.refresh_token,
        });
        console.log(res);
        if (
          res.applicationmapping.length === 1 &&
          !res.applicationmapping[0].SupersetApplication.Enabled &&
          res.applicationmapping[0].SupersetApplication.IsAddedOnce
        )
          notyf.open({
            type: "warning",
            message:
              "Telemetry is Paused for Superset since Superset view is disabled.",
          });
        history.replace("/platform");
      }
    }
    setIsLoading(false);
  }

  function handleForgotPassword() {
    setSectionToShow(FORGOT_PASSWORD);
    setErrors({});
    setPassword("");
    setUsername("");
  }

  function handleBackToLogin() {
    setSectionToShow(LOGIN);
    setErrorsForgotPassword("");
    setEmailId("");
  }

  const onChangePhone = (phoneNumber, country, e, formattedValue) => {
    if (phoneNumber === country.dialCode) {
      setErrors((prev) => ({
        ...prev,
        phoneNumber: "Mobile number is required",
      }));
    } else {
      let _errors = { ...errors };
      delete _errors.phoneNumber;
      setErrors(_errors);
    }
    setSelectedCountry(country);

    setPhoneNumber(phoneNumber);
  };

  const renderWrongAttempt = () => {
    return (
      isWrongAttempt && (
        <p className="login_wrongAttemptContainer">
          After 5 incorrect password attempts, your account will be temporarily
          deactivated
        </p>
      )
    );
  };

  return (
    <div className={`h100 login_container`}>
      <div className={`center login_loginWrapper`}>
        <div className={`login_login txt-center`}>
          <img
            //src="http://localhost:9003/src/assets/images/logo.png"
            src={companyDetails?.Logo || ""}
            alt="Company Logo"
            height="50"
          ></img>
          <div className={`txt-center flex login_head`}>
            <LockIcon
              width={screen.width >= 480 ? 50 : 30}
              height={screen.width >= 480 ? 50 : 30}
              strokeWidth="2"
            />
          </div>

          {sectionToShow === LOGIN && (
            <>
              <span className="login_headText">Login</span>

              <form noValidate>
                <div className="login_form">
                  <Tabs
                    defaultActiveKey={userNameType}
                    className="login_tab-container"
                    onChange={(e) => {
                      setUserNameType(e);

                      // setErrors((prev) => {
                      //   let _prev = { ...prev };
                      //   delete _prev.username;
                      //   return _prev;
                      // });
                    }}
                  >
                    <TabPane tab="Email" key="Email">
                      <input
                        id="username"
                        name="username"
                        type="text"
                        className={`input`}
                        value={username}
                        onChange={onChange}
                        required
                        autoFocus
                      />
                      <p className="field-error txt-left del-mobile">
                        {errors.username || ""}
                      </p>
                      <p className="field-error del-not-mobile">
                        {errors.username || ""}
                      </p>
                    </TabPane>
                    <TabPane tab="Mobile" key="Mobile">
                      <PhoneInput
                        preferredCountries={["in", "us"]}
                        country="in"
                        value={phoneNumber}
                        onChange={(value, country, e, formattedValue) =>
                          onChangePhone(value, country, e, formattedValue)
                        }
                        inputProps={{
                          name: "Mobile",
                          id: "Mobile",
                        }}
                        defaultMask="..... ....."
                        specialLabel=""
                        countryCodeEditable={false}
                        inputClass={"input"}
                        containerClass={"login_input-width"}
                      />
                      <p className="field-error txt-left del-mobile">
                        {errors.phoneNumber || ""}
                      </p>
                      <p className="field-error del-not-mobile">
                        {errors.phoneNumber || ""}
                      </p>
                    </TabPane>
                  </Tabs>
                  <Tabs
                    defaultActiveKey={loginType}
                    className="login_tab-container flex-grow"
                    onChange={(e) => {
                      setLoginType(e);
                    }}
                    id="password_and_otp"
                  >
                    <TabPane tab={PASSWORD} key={PASSWORD}>
                      <div className={`login_input-grp`}>
                        <label htmlFor="password" className={`input-label`}>
                          Password
                        </label>
                        <input
                          id="password"
                          name="password"
                          type="password"
                          className={`input`}
                          value={password}
                          onChange={onChange}
                          required
                        />
                        <p className="field-error txt-left del-mobile">
                          {errors.password || ""}
                        </p>
                        <p className="field-error del-not-mobile">
                          {errors.password || ""}
                        </p>
                      </div>
                      <button
                        className={`button_forgot`}
                        id="forgotButton"
                        type="button"
                        onClick={handleForgotPassword}
                      >
                        Forgot Password?
                      </button>
                    </TabPane>

                    <TabPane tab={OTP} key={OTP}>
                      <div className="txt-center mb1">
                        <button
                          className="login_OTP-button"
                          disabled={!companyDetails || isLoading}
                          id="loginOTPButton"
                          type="button"
                          onClick={showOTPSection}
                        >
                          Generate OTP
                        </button>
                      </div>
                    </TabPane>
                  </Tabs>
                </div>
                <div className={`txt-center login_foot`}>
                  <button
                    className={`btn`}
                    disabled={
                      !companyDetails || isLoading || loginType !== PASSWORD
                    }
                    id="loginButton"
                    onClick={onLogin}
                  >
                    Login
                  </button>
                  {renderWrongAttempt()}
                </div>
              </form>
            </>
          )}

          {sectionToShow === FORGOT_PASSWORD && (
            <>
              <span className="login_headText">Forgot Password</span>
              <form noValidate>
                <div>
                  <div className={`input-grp login_grp`}>
                    <label htmlFor="email_id" className={`input-label`}>
                      Username
                    </label>

                    <input
                      id="email_id"
                      name="email_id"
                      type="text"
                      className={`input`}
                      value={email_id}
                      onChange={(e) => {
                        onChangeEmail(e.target.value);
                      }}
                      required
                      autoFocus
                    />
                    <p className="field-error txt-left del-mobile">
                      {errors_forgotPassword}
                    </p>
                  </div>
                  <p className="field-error del-not-mobile">
                    {errors_forgotPassword}
                  </p>
                  <button
                    className={`button_forgot`}
                    id="backButton"
                    type="button"
                    onClick={() => {
                      handleBackToLogin();
                    }}
                  >
                    Back to Login
                  </button>
                </div>
                <div className={`txt-center login_foot`}>
                  <button
                    className={`btn`}
                    onClick={onForgotPassword}
                    id="submitButton"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </>
          )}

          {sectionToShow === OTP && (
            <OTPSection
              back={() => setSectionToShow(LOGIN)}
              generateOTP={generateOTP}
              onLogin={onLogin}
              renderWrongAttempt={renderWrongAttempt}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default Login;
