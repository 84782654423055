import React, { createContext, useEffect, useState } from "react";

export const ApplicationContext = createContext();

export default function AppProvider({ children }) {
  const [application, setApplication] = useState({
    name: sessionStorage.getItem("appName") || "",
    id: sessionStorage.getItem("appId") || "",
    userType: localStorage.getItem("userType") || "",
    applicationList: localStorage.getItem("applicationList") || "[]",
  });

  useEffect(() => {
    setApplication({
      name: sessionStorage.getItem("appName") || "",
      id: sessionStorage.getItem("appId") || "",
      userType: localStorage.getItem("userType") || "",
      applicationList: localStorage.getItem("applicationList") || "[]",
    });
  }, []);

  return (
    <ApplicationContext.Provider value={{ application, setApplication }}>
      {children}
    </ApplicationContext.Provider>
  );
}
