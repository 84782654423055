import React from "react";

function Menu(props) {
  return (
    <svg viewBox="0 0 384 384" width="20" height="20" {...props}>
      <path d="M0 277.333h384V320H0zM0 170.667h384v42.667H0zM0 64h384v42.667H0z" />
    </svg>
  );
}
export default Menu;
