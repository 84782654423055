import React, { useState, useEffect } from "react";
import useAuth from "../lib/hooks/useAuth";
import Modal from "./Modal";
import { delApi } from "../lib/delApi";
import "./DropDownMenu.css";
import EyeOutline from "../icons/eye-outline.js";
import EyeOffOutline from "../icons/eyeoff-outline.js";
import { Avatar } from "antd";
import { capitalizeFirstLetter } from "../lib/utils/helper";

export default function DropDownMenu({ iconClickAction, children }) {
  const [isActive, setIsActive] = useState(false);
  const { logout, username, mobilenumber } = useAuth();
  const [alertLogoutModal, setAlertLogoutModal] = useState(false);

  //   For Reset Password :
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetModal, setResetModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [warnings, setWarnings] = useState({});
  const [isRevealPwdNew, setIsRevealPwdNew] = useState(false);
  const [isRevealPwdConfirm, setIsRevealPwdConfirm] = useState(false);
  const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
  // End of Reset Password States
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [versions, setVersions] = useState(null);

  const onClick = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    const onClick = (e) => {
      setIsActive(!isActive);
    };

    if (isActive) {
      window.addEventListener("click", onClick);
    }

    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [isActive]);

  //   Reset Password Functions
  function onChange({ target: { name, value } }) {
    name === "oldPassword"
      ? setOldPassword(value)
      : name === "newPassword"
      ? setNewPassword(value)
      : setConfirmPassword(value);

    let _errors = { ...errors };
    if (value === "") {
      setErrors((s) => ({
        ...s,
        [name]: "This field is required",
      }));
    } else {
      if (name == "newPassword") {
        const regExp = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        if (!value.match(regExp)) {
          setErrors((s) => ({
            ...s,
            [name]:
              "Use 8 or more characters with a mix of upper cases, lower cases, numbers & symbols",
          }));
        } else {
          delete _errors[name];
          setErrors(_errors);
        }
      } else {
        delete _errors[name];
        setErrors(_errors);
      }
      let _warnings = { ...warnings };
      if (value.length >= 16) {
        setWarnings((s) => ({
          ...s,
          [name]: "Password should not be more than 16 characters long",
        }));
      } else {
        delete _warnings[name];
        setWarnings(_warnings);
      }
    }
  }

  function validateData() {
    let _errors = { ...errors };
    if (oldPassword === "") {
      _errors.oldPassword = "This field is required";
    }
    if (newPassword === "") {
      _errors.newPassword = "This field is required";
    }
    if (confirmPassword === "") {
      _errors.confirmPassword = "This field is required";
    }
    if (confirmPassword && newPassword) {
      if (newPassword !== confirmPassword) {
        _errors.confirmPassword = "Passwords do not match";
      }
    }
    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  const openUserProfile = () => {
    setIsUserProfileOpen(true);
  };

  function openResetPasswordForm() {
    iconClickAction && iconClickAction();
    setIsRevealPwdConfirm(false);
    setIsRevealPwdNew(false);
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setErrors({});
    setWarnings({});
    setResetModal(true);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const apiLink = "/admin/api/authentication/resetpassword";
    if (validateData()) {
      const payload = {
        CompanyId: localStorage.getItem("companyId"),
        UserName: localStorage.getItem("uname"),
        RealmName: localStorage.getItem("realmName"),
        OldPassword: oldPassword,
        NewPassword: newPassword,
        ConfirmPassword: confirmPassword,
      };
      console.log(payload);
      const res = await delApi(
        apiLink,
        "POST",
        payload,
        "Password Updated",
        null,
        { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
      );
      if (res) {
        setResetModal(false);
        setTimeout(logout, 5000);
        setAlertLogoutModal(true);
      } else {
        setOldPassword("");
      }
    }
  }
  //   End of Reset Password Functions

  return (
    <div className="menu-container">
      <button onClick={onClick} className="menu-trigger" id="drop-down-icon">
        {children}
      </button>
      <nav className={`menu ${isActive ? "active" : "inactive"}`}>
        <ul>
          <li
            className={`button_transparent`}
            id="userProfileButton"
            onClick={() => {
              openUserProfile(true);
            }}
          >
            User Profile
          </li>
        </ul>
        <ul>
          <li
            className={`button_transparent`}
            id="changePasswordButton"
            onClick={() => {
              openResetPasswordForm();
            }}
          >
            Change Password
          </li>
        </ul>
        <ul>
          <li
            className={`button_transparent`}
            id="aboutButton"
            onClick={() => {
              setIsAboutOpen(true);
            }}
          >
            About
          </li>
        </ul>
      </nav>

      <Modal
        isOpen={alertLogoutModal}
        closeModal={() => setAlertLogoutModal(false)}
        noActions={true}
        enableModalClose={false}
      >
        <div className="txt-center">
          <p>You are now being logged out.</p>
          <p>Please login with the new password.</p>
        </div>
      </Modal>

      <Modal
        isOpen={isUserProfileOpen}
        closeModal={() => setIsUserProfileOpen(false)}
        noActions={true}
        heading="User Profile"
        enableModalClose={true}
      >
        <div className="txt-center mt10">
          <Avatar
            className="dd_avatar-background"
            size={{ xs: 32, sm: 40, md: 64, lg: 64, xl: 64, xxl: 64 }}
          >
            {capitalizeFirstLetter(username[0] || "U")}
          </Avatar>
          <div className="dd_user-profile-container">
            {username}
            {mobilenumber && (
              <>
                <br />
                <br />
                {mobilenumber}
              </>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={resetModal}
        heading="Change Password"
        closeModal={() => setResetModal(false)}
        primaryActionName="Change"
        onSubmit={handleSubmit}
        enableModalClose={false}
      >
        <div>
          <div className={`input-grp`}>
            <label htmlFor="oldPassword" className={`input-label`}>
              Old Password
            </label>
            <input
              id="oldPassword"
              name="oldPassword"
              type="password"
              maxlength="16"
              className={`input mw-none`}
              value={oldPassword}
              onChange={onChange}
              required
              autoFocus
            />
          </div>
          {errors.oldPassword ? (
            <p className="field-error error-text">
              {errors.oldPassword || ""}{" "}
            </p>
          ) : (
            <p className="layout-field-warning warning-text">
              {warnings.oldPassword || ""}{" "}
            </p>
          )}
          <div className={`input-grp`}>
            <label htmlFor="newPassword" className={`input-label`}>
              New Password
            </label>
            <div class="pwd">
              <input
                id="newPassword"
                name="newPassword"
                maxlength="16"
                type={isRevealPwdNew ? "text" : "password"}
                className={`input mw-none`}
                value={newPassword}
                onChange={onChange}
                required
              />
              <span
                class="p-viewer"
                onClick={() => setIsRevealPwdNew((prevState) => !prevState)}
                id="showPasswordNew"
              >
                {isRevealPwdNew ? (
                  <EyeOffOutline stroke="grey" fill="grey" />
                ) : (
                  <EyeOutline stroke="grey" />
                )}
              </span>
            </div>
          </div>
          {errors.newPassword ? (
            <p className="field-error error-text">
              {errors.newPassword || ""}{" "}
            </p>
          ) : (
            <p className="layout-field-warning warning-text">
              {warnings.newPassword || ""}{" "}
            </p>
          )}

          <div className={`input-grp`}>
            <label htmlFor="confirmPassword" className={`input-label`}>
              Confirm Password
            </label>
            <div class="pwd">
              <input
                id="confirmPassword"
                name="confirmPassword"
                maxlength="16"
                type={isRevealPwdConfirm ? "text" : "password"}
                className={`input mw-none`}
                value={confirmPassword}
                onChange={onChange}
                required
              />
              <span
                class="p-viewer"
                onClick={() => setIsRevealPwdConfirm((prevState) => !prevState)}
                id="showPasswordConfirm"
              >
                {isRevealPwdConfirm ? (
                  <EyeOffOutline stroke="grey" fill="grey" />
                ) : (
                  <EyeOutline stroke="grey" />
                )}
              </span>
            </div>
          </div>
          {errors.confirmPassword ? (
            <p className="field-error error-text">
              {errors.confirmPassword || ""}{" "}
            </p>
          ) : (
            <p className="layout-field-warning warning-text">
              {warnings.confirmPassword || ""}{" "}
            </p>
          )}
        </div>
      </Modal>

      <Modal
        isOpen={isAboutOpen}
        heading="About"
        closeModal={() => setIsAboutOpen(false)}
        noActions={true}
        enableModalClose={true}
      >
        <div className="ddMenu-aboutGrid">
          <div>Version </div>
          <div>: {window.platFormApp && window.platFormApp.Version}</div>
        </div>
      </Modal>
    </div>
  );
}
