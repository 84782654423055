import React, { useRef } from "react";
import ReactModal from "react-modal";
import "./modal.css";
import Close from "../icons/close-outline";
import { v4 as uuidv4 } from "uuid";

ReactModal.setAppElement("#root-main");

export default function Modal({
  isOpen = false,
  heading,
  children,
  closeModal,
  customClass = "",
  contentClass = "",
  customActions = null,
  primaryActionName = "Save",
  enableModalClose = true,
  onSubmit,
  noActions,
  autoFocusAction,
}) {
  const uuid = useRef(uuidv4());
  const headingId = `${uuid.current}-h`;
  const descId = `${uuid.current}-d`;
  return (
    <ReactModal
      isOpen={isOpen}
      aria={{ labelledby: headingId, describedby: descId }}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={enableModalClose}
      className={`center flex col modal_modal ${customClass}`}
      overlayClassName="modal_overlay"
    >
      <div className={`flex al-ctr modal_header`}>
        <h5 id={headingId} className="modal_h5">
          {heading}
        </h5>
        <button
          onClick={closeModal}
          className={`icon-btn modal_close`}
          id={`${(heading && heading.replace(/\s/g, "")) || ""}modalClose`}
        >
          <Close stroke="var(--primary)" />
        </button>
      </div>
      <div id={descId} className={`modal_body ${contentClass} mh0`}>
        <form
          className={`h100 flex col`}
          onSubmit={(e) => onSubmit && onSubmit(e)}
          noValidate
        >
          <div className="modal_children">{children}</div>
          {!noActions && (
            <div className={`flex al-ctr just-ctr modal_actions`}>
              {!customActions ? (
                <>
                  <button
                    type="submit"
                    id={`${
                      (heading && heading.replace(/\s/g, "")) || ""
                    }primaryAction`}
                    className={`btn modal_modalBtn`}
                    autoFocus={!!autoFocusAction}
                  >
                    {primaryActionName}
                  </button>
                  <button
                    type="reset"
                    className={`btn`}
                    onClick={closeModal}
                    id={`${
                      (heading && heading.replace(/\s/g, "")) || ""
                    }Cancel`}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                customActions?.map((a) => a)
              )}
            </div>
          )}
        </form>
      </div>
    </ReactModal>
  );
}
