import downloadCsv from "./utils/downloadCsv";
import downloadZip from "./utils/downloadZip";

async function parseFetchResponse(response) {
  const contentType = response.headers.get("content-type");

  if (contentType) {
    if (contentType.indexOf("application/json") !== -1) {
      return await response.json();
    } else if (contentType.indexOf("text/plain") !== -1)
      return await response.text();
    else if (contentType.indexOf("application/zip") !== -1) {
      downloadZip(await response.blob());
    } else if (contentType.indexOf("text/csv") !== -1) {
      downloadCsv(await response.blob());
    } else if (contentType.indexOf("image/jpeg") !== -1) {
      return await response.blob();
    }
  }
  return null;
}
export default parseFetchResponse;
