import React from "react";

export default function MinusSymbol(props) {
  return (
    <svg {...props} width="20" height="10" viewBox="0 0 92 92">
      <path
        fill={props.fill || "#000"}
        d="M68,50.5H24c-2.5,0-4.5-2-4.5-4.5s2-4.5,4.5-4.5h44c2.5,0,4.5,2,4.5,4.5S70.5,50.5,68,50.5z"
      />
    </svg>
  );
}
