import { useEffect, useRef, useState } from "react";

export function useInterval(callback, delay) {
  const isTimerActive = useRef(false);
  const savedCallback = useRef();
  //const [ts, setTs] = useState(false);
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      //setTs(true);
      isTimerActive.current = true;
      let id = setInterval(tick, delay);
      console.log(`[timer ${id}] tick scheduled for ${delay}`);
      return () => {
        console.log(`[timer ${id}] clearing interval`);
        clearInterval(id);
        isTimerActive.current = false;
        //setTs(false);
      };
    }
  }, [delay]);

  return { isTimerActive };
}
