import React, { useEffect, useState, useContext } from "react";
import useAuth from "./lib/hooks/useAuth";
import "notyf/notyf.min.css";
import NotyfContext from "./lib/contexts/NotyfContext";
import fetcher from "./lib/delApi";
import { SWRConfig } from "swr";
import Login from "./components/Login/Login";
import Layout from "./components/layout";
import { useLocation, useHistory } from "react-router";
import { ApplicationContext } from "./lib/contexts/ApplicationContext";
import qs from "query-string";
import { SVELT_APPS } from "./lib/utils/layoutConstants";

const options = {
  fetcher,
  shouldRetryOnError: false,
  revalidateOnFocus: false,
};

const companyUrlpaths = [
  "/platform/Users",
  "/platform/Provisioning",
  "/platform/DataSources",
];

function App() {
  const { isAuthenticated, logout } = useAuth();
  const [notyf, setNotyf] = useState(null);
  const { pathname } = useLocation();
  const history = useHistory();
  const isIframe = window.self !== window.top;
  const { search: queryString, hash: hashString } = useLocation();
  const { setApplication } = useContext(ApplicationContext);
  const query = queryString
    ? qs.parse(queryString)
    : hashString
    ? qs.parse(hashString.substr(hashString.indexOf("?") + 1))
    : {};

  useEffect(() => {
    import("notyf").then(({ Notyf }) => {
      setNotyf(
        new Notyf({
          duration: 5000,
          dismissible: true,
          position: { x: "right", y: "top" },
          types: [
            { type: "success", background: "hsl(170, 91%, 32%)" },
            {
              type: "warning",
              background: "#ffe827",
              className: "del_warning-toast-wrapper",
            },
          ],
        })
      );
    });
  }, []);

  useEffect(() => {
    if (notyf) {
      if (query?.appId && query?.appName) {
        const applist = JSON.parse(
          localStorage.getItem("applicationList") || "[]"
        );
        const newApp = applist.find(
          (app) => app.ApplicationId === query?.appId
        );
        if ((newApp === null || newApp === undefined) && isAuthenticated) {
          notyf.error("You do not have permission to access this application.");
          history.push("/platform");
        } else {
          sessionStorage.setItem("appName", query.appName);
          sessionStorage.setItem("appId", query.appId);
        }
      }
      if (isAuthenticated === false) {
        logout(true);
      }
      if (pathname === "/" && isAuthenticated) {
        const redirectLink = localStorage.getItem("link");
        localStorage.removeItem("link");
        const applist = JSON.parse(
          localStorage.getItem("applicationList") || "[]"
        );
        const usertype = localStorage.getItem("userType");
        if (redirectLink) return history.push(redirectLink);
        applist.forEach((app) => {
          if (applist.length === 1) {
            sessionStorage.setItem("appName", app.ApplicationName);
            sessionStorage.setItem("appId", app.ApplicationId);
            setApplication((s) => ({
              ...s,
              name: app.ApplicationName,
              id: app.ApplicationId,
            }));
            var event = new CustomEvent("applicationChange", {
              detail: {
                message: "Application Changed",
                name: app.ApplicationName,
                id: app.ApplicationId,
                applicationList:
                  localStorage.getItem("applicationList") || "[]",
              },
              bubbles: true,
              cancelable: true,
            });
            document.body.dispatchEvent(event);
            if (
              usertype === "companyAdmin" ||
              app.RoleName === "Application Admin"
            )
              history.push("/platform/HierarchyView");
            else
              history.push(
                `/${app.ApplicationName?.toLowerCase().replace(/\s/g, "")}`
              );
          } else if (applist.length > 1) {
            history.push("/platform");
          }
        });
      }
      if (pathname !== "/" && isAuthenticated) {
        localStorage.removeItem("link");
        if (!companyUrlpaths.includes(pathname)) {
          const appId = sessionStorage.getItem("appId");
          const appName = sessionStorage.getItem("appName");
          if (!appId) {
            history.push("/platform");
          }
          if (appId && appName && location.href.includes("#")) {
            const appFromUrl = location.href.split("#")[0]?.split("/")?.at(-1);
            const application =
              SVELT_APPS.map((app) => app + "_old")
                ?.find((app) => app === appFromUrl)
                ?.substr(0, appFromUrl.length - 4) || appFromUrl;
            let _appName = appName.replace(/\s/g, "");
            if (application !== _appName?.toLowerCase()) {
              const applist = JSON.parse(
                localStorage.getItem("applicationList") || "[]"
              );
              const newApp = applist.find(
                (app) => app.ApplicationName?.toLowerCase() === application
              );
              if (newApp === null || newApp === undefined) {
                notyf.error(
                  "You do not have permission to access this application."
                );
                history.push("/platform");
                return;
              }
              sessionStorage.setItem("appId", newApp.ApplicationId);
              sessionStorage.setItem("appName", newApp.ApplicationName);
            }
          }
        }
      }
    }
  }, [isAuthenticated, notyf, location.href]);

  return (
    <NotyfContext.Provider value={notyf}>
      <SWRConfig value={options}>
        {isIframe && !query?.source ? (
          <Layout />
        ) : isAuthenticated ? (
          <Layout />
        ) : (
          <Login />
        )}
      </SWRConfig>
    </NotyfContext.Provider>
  );
}
export default App;
