export const SUPERSET_SCREENS = [
  "hierarchySchemasNavItem",
  "hierarchyViewNavItem",
  "derivedParameterListNavItem",
  "showConfigurations",
];

export const SVELT_APPS = [];

export const ANNOTATION_TOOL_URL = process.env.ANNOTATION_TOOL_URL;

export const UNAUTHORIZED_STATUS_CODE = 401;
