import "./layout.css";
import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router";
import { Link, NavLink, useHistory } from "react-router-dom";
import useAuth from "../lib/hooks/useAuth";
import Logout from "../icons/logout";
import Menu from "../icons/menu";
import ChevronForwardOutline from "../icons/chevron-forward-outline";
import ChevronDownOutline from "../icons/chevron-down-outline";
import User from "../icons/user";
import Home from "../icons/home";
import useUpdateEffect from "../lib/hooks/useUpdateEffect";
import Modal from "./Modal";
import { useRefreshToken } from "../lib/hooks/useRefreshToken";
import { ApplicationContext } from "../lib/contexts/ApplicationContext";
import NotFound from "./NotFound";
import UserFilled from "../icons/user-filled";
import DropDownMenu from "./DropDownMenu";
import DelpheonLogo from "../assets/images/logo.png";
import MinusSymbol from "../icons/minus-symbol";
import {
  checkIfNeedOldApp,
  checkIfOEEApp,
  checkIfRDPMSApp,
  hasCustomApp,
} from "../lib/utils/helper";
import {
  ANNOTATION_TOOL_URL,
  SUPERSET_SCREENS,
} from "../lib/utils/layoutConstants";
import NewTab from "../icons/new-tab";

const routes = ["platform"];
const ANALYTICS = "Analytics";
const PREDICTIVE_ANALYTICS = "Predictive Analytics";
export default function Layout() {
  const { pathname } = useLocation();
  const { username, logout } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);
  const [navExpanded, setNavExpanded] = useState({});
  const [logoutModal, setLogoutModal] = useState(false);
  const history = useHistory();
  const [filteredNav, setFilteredNav] = useState([]);
  const { application, setApplication } = useContext(ApplicationContext);

  const [home, setHome] = useState(pathname === "/platform");
  const [prov, setProv] = useState(pathname === "/platform/Provisioning");
  const isIframe = window.self !== window.top;

  const appList = JSON.parse(application?.applicationList || "[]");
  appList.forEach((x) =>
    routes.push(x.ApplicationName.toLowerCase().replace(/\s/g, ""))
  );

  let navLinks = [
    {
      name: "Hierarchy Schemas",
      href: "/platform/HierarchySchemas",
      id: "hierarchySchemasNavItem",
      isCollapsible: false,
    },
    {
      name: "Hierarchy View",
      href: "/platform/HierarchyView",
      id: "hierarchyViewNavItem",
      isCollapsible: false,
    },
    {
      name: "User",
      href: null,
      id: "userNavItem",
      isCollapsible: true,
      children: [
        {
          name: "User-Role Mapping",
          href: "/platform/UserRoleMapping",
          id: "usersNavItem",
          isCollapsible: false,
        },
        {
          name: "User Roles",
          href: "/platform/UserRoles",
          id: "rolesNavItem",
          isCollapsible: false,
        },
      ],
    },

    {
      name: "Derived Parameter List",
      href: "/platform/DerivedParameterList",
      id: "derivedParameterListNavItem",
      isCollapsible: false,
    },
    {
      name: "Alerts",
      href: "/platform/Alerts",
      id: "alertsNavNavItem",
      isCollapsible: false,
    },
    {
      name: "Schedulers",
      href: "/platform/Schedulers",
      id: "showShedulers",
      isCollapsible: false,
    },
    {
      name: "Configurations",
      href: "/platform/Configurations",
      id: "showConfigurations",
      isCollapsible: false,
    },
    {
      name: "Dashboards",
      href: `/${application.name.toLowerCase().replace(/\s/g, "")}`,
      id: "dashboardsNavItem",
      isCollapsible: false,
    },
    {
      name: "Parameter Ranges",
      href: "/platform/ParameterRanges",
      id: "showParameterRanges",
      isCollapsible: false,
    },
    {
      name: "External Parameters",
      href: null,
      id: "ExternalNavItem",
      isCollapsible: true,
      children: [
        {
          name: "Configuration",
          href: "/platform/ExternalParameters",
          id: "showExternalParameters",
          isCollapsible: false,
        },
        {
          name: "Data Import",
          href: "/platform/DataImport",
          id: "dataImportNavItem",
          isCollapsible: false,
        },
        {
          name: "Data Export",
          href: "/platform/DataExport",
          id: "dataExportNavItem",
          isCollapsible: false,
        },
      ],
    },
  ];

  const currentAppId = sessionStorage.getItem("appId");
  const currentAppFeatures = appList?.find(
    (app) => app.ApplicationId === currentAppId
  )?.Features;
  if (currentAppFeatures?.includes(ANALYTICS)) {
    navLinks.push({
      name: "Analytics",
      href: "/platform/Analytics",
      id: "analyticsNavItem",
      isCollapsible: false,
    });
  }

  //Add check to find if the application is OEE here

  if (checkIfOEEApp()) {
    navLinks.push({
      name: "OEE Settings",
      href: "/platform/OEESettings",
      id: "OEESettingsNavItem",
      isCollapsible: false,
    });
    navLinks.push({
      name: "OEE Templates",
      href: "/platform/OEETemplateList",
      id: "OEETemplateListNavItem",
      isCollapsible: false,
    });
  }

  if (currentAppFeatures?.includes(PREDICTIVE_ANALYTICS)) {
    navLinks.push({
      name: "RDPMS Analytics",
      href: "/platform/PredictiveAnalytics",
      id: "predictiveAnalyticsNavItem",
      isCollapsible: false,
    });
  }

  if (checkIfRDPMSApp()) {
    navLinks.push({
      name: "Files",
      href: "/platform/Files",
      id: "filesNavItem",
      isCollapsible: false,
    });
    navLinks.push({
      name: "Data Integrity",
      href: null,
      id: "dataIntegrityNavItem",
      isCollapsible: true,
      children: [
        {
          name: "Configuration",
          href: "/platform/DataIntegrityConfig",
          id: "DataIntegrityConfig",
          isCollapsible: false,
        },
        {
          name: "Report",
          href: "/platform/DataIntegrityReport",
          id: "DataIntegrityReport",
          isCollapsible: false,
        },
        {
          name: "Correlation Parameters",
          href: "/platform/CorrelationFormula",
          id: "DataIntegrityReport",
          isCollapsible: false,
        },
      ],
    });
  }

  navLinks.push({
    name: "Application Settings",
    href: "/platform/AppSettings",
    id: "ApplicationSettingsNavItem",
    isCollapsible: false,
  });

  function toggleSideMenu() {
    setMenuOpen((s) => !s);
  }
  function onLogout(e) {
    e.preventDefault();
    history.replace("/");
    logout();
  }
  useRefreshToken();

  useUpdateEffect(() => {
    if (pathname === "/platform" || pathname === "/platform/") setHome(true);
    else setHome(false);
    if (
      pathname === "/platform/Provisioning" ||
      pathname === "/platform/Users" ||
      pathname === "/platform/DataSources"
    )
      setProv(true);
    else setProv(false);
  });

  useEffect(() => {
    const handleApplicationChange = ({
      detail: { name, id, applicationList, message },
    }) => {
      console.log(message);
      sessionStorage.setItem("application-initial-load", "true");
      setApplication((s) => ({ ...s, name, id, applicationList }));
    };

    const handleMultiTabLogout = (e) => {
      if (e.key === null) {
        console.log("Logout triggered from another Tab");
        logout();
      }
    };

    const handleLogoutEvent = () => {
      logout();
    };

    const handleVisibility = () => {
      if (document.visibilityState === "visible") {
        const lastVist = localStorage.getItem("LastVisit") || Date.now();
        const timeDelta = Date.now() - lastVist;
        const isGreaterThanTwoHour = timeDelta > 2 * 60 * 60 * 1000;
        if (isGreaterThanTwoHour) {
          console.log("Logout triggred due to 2 hrs of inactivity", lastVist);
          logout();
        }
      } else {
        localStorage.setItem("LastVisit", Date.now());
      }
    };

    window.addEventListener("applicationChange", handleApplicationChange);
    window.addEventListener("storage", handleMultiTabLogout);
    window.addEventListener("logoutEvent", handleLogoutEvent);
    document.addEventListener("visibilitychange", handleVisibility);

    return () => {
      window.removeEventListener("applicationChange", handleApplicationChange);
      window.removeEventListener("storage", handleMultiTabLogout);
      window.removeEventListener("logoutEvent", handleLogoutEvent);
      document.removeEventListener("visibilitychange", handleVisibility);
    };
  }, []);

  useEffect(() => {
    if (application) {
      const appRole = JSON.parse(application?.applicationList).find(
        (app) => app.ApplicationId === application.id
      )?.RoleName;
      setFilteredNav(() => {
        //Remove Dashboard if application is RDPMS
        let _navLinks = navLinks;
        if (application.name.toLowerCase().replace(/\s/g, "") === "rdpms") {
          _navLinks = _navLinks.filter((nav) => nav.name !== "Dashboards");
        }

        //Remove Dashboard if application is superSet
        if (!hasCustomApp()) {
          _navLinks = _navLinks.filter(({ id }) =>
            SUPERSET_SCREENS.includes(id)
          );
        }

        if (checkIfNeedOldApp() && hasCustomApp()) {
          _navLinks = [
            ..._navLinks,
            {
              name: "Old Dashboards",
              href: `/${application.name.toLowerCase().replace(/\s/g, "")}_old`,
              id: "oldDashboardsNavItem",
              isCollapsible: false,
            },
          ];
        }

        return appRole === "Application Admin" ||
          application.userType === "companyAdmin"
          ? _navLinks
          : _navLinks.filter((nav) => {
              if (nav.name === "Dashboards" || nav.name === "Old Dashboards") {
                return nav;
              }
            });
      });
    }
  }, [application]);

  const openAnnotaionTool = () => {
    console.log("open");
    window.open(
      `${ANNOTATION_TOOL_URL}/?token=${localStorage.getItem(
        "access_token"
      )}&refresh_token=${localStorage.getItem(
        "refresh_token"
      )}&companyid=${localStorage.getItem(
        "companyId"
      )}&applicationid=${sessionStorage.getItem(
        "appId"
      )}&realmName=${localStorage.getItem("realmName")}`,
      "_blank"
    );
  };

  return isIframe ? null : (
    <div>
      <div className={`layout_sidenav ${menuOpen ? "layout_wtransition" : ""}`}>
        <div className={`flex layout_user`}>
          {!home && (
            <Link
              to="/platform"
              id="layoutUserHome"
              className={`flex al-ctr`}
              exact
              onClick={() => setMenuOpen(false)}
            >
              <Home fill="var(--primary)" className="layout_homeIcon" />
              Home
            </Link>
          )}
          <div className="layout_logout">
            <div>
              <DropDownMenu iconClickAction={() => setMenuOpen(false)}>
                <svg
                  focusable="false"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="1.7rem"
                  fill="#3030b5"
                >
                  <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
                </svg>
              </DropDownMenu>
            </div>
            <div>
              <button
                type="button"
                className={`flex al-ctr icon-btn`}
                id="sideNavLogout"
                title="Logout"
                style={{ verticalAlign: "middle" }}
                onClick={() => {
                  setMenuOpen(false);
                  setLogoutModal(true);
                }}
              >
                <Logout fill="var(--primary)" width="18" height="18" />
              </button>
            </div>
          </div>
        </div>
        <div className="layout_userIconWrapper">
          <User
            width={50}
            height={50}
            fill="var(--secondary)"
            className="layout_userIcon"
          />
        </div>
        <div className={`txt-center mb20 layout_uname`}>{username}</div>

        <div className="layout_homeDiv">
          {!home && (
            <Link
              to="/platform"
              className={`flex al-ctr`}
              id="homeNavItem"
              exact
              onClick={() => {
                setMenuOpen(false);
                setNavExpanded({});
              }}
            >
              <Home fill="var(--primary)" className="layout_homeIcon" />
              Home
            </Link>
          )}
        </div>
        {!home && !prov && (
          //&& application &&
          <div className="layout_sidenavlinks">
            <div>
              {/* <h3 className="layout_appName">{application}</h3> */}
              {filteredNav.map((link) =>
                link.isCollapsible === false ? (
                  <NavLink
                    to={link.href}
                    key={link.name}
                    exact
                    className={`flex al-ctr layout_navItem ${
                      pathname.split("/")[1] ===
                        application.name.toLowerCase().replace(/\s/g, "") &&
                      link.name === "Dashboards"
                        ? "selectedLink"
                        : ""
                    }`}
                    activeClassName="selectedLink"
                    onClick={() => {
                      setMenuOpen(false);
                      if (link.name === "Dashboards") {
                        sessionStorage.setItem(
                          "application-initial-load",
                          "true"
                        );
                      }
                    }}
                    id={`${link.id}`}
                  >
                    <MinusSymbol
                      width="15"
                      height="15"
                      fill={
                        pathname === link.href ? "#f06605" : "var(--primary)"
                      }
                      stroke={
                        pathname === link.href ? "#f06605" : "var(--primary)"
                      }
                    />
                    <span>{link.name}</span>
                  </NavLink>
                ) : (
                  <>
                    <a
                      className={`flex al-ctr layout_navItem layout_navExpandItem`}
                      onClick={() => {
                        setNavExpanded((prev) => ({
                          ...prev,
                          [link.id]: !prev[link.id],
                        }));
                      }}
                    >
                      {!navExpanded[link.id] && (
                        <ChevronForwardOutline
                          width="20"
                          height="15"
                          stroke="var(--primary)"
                          strokeWidth="40"
                        />
                      )}
                      {navExpanded[link.id] && (
                        <ChevronDownOutline
                          width="20"
                          height="15"
                          stroke="#f06605"
                          strokeWidth="48"
                        />
                      )}
                      <span
                        className={
                          link.children.find((child) => child.href === pathname)
                            ? "selectedLink"
                            : ""
                        }
                      >
                        {link.name}
                      </span>
                    </a>
                    {navExpanded[link.id] && (
                      <>
                        {link.children.map((link) => (
                          <NavLink
                            to={link.href}
                            key={link.name}
                            exact
                            className={`flex al-ctr layout_navItem`}
                            activeClassName="selectedLink"
                            onClick={() => {
                              setMenuOpen(false);
                            }}
                            id={`${link.id}`}
                          >
                            <div className="ml_20">
                              <MinusSymbol
                                fill={
                                  pathname === link.href
                                    ? "#f06605"
                                    : "var(--primary)"
                                }
                                width="15"
                                height="15"
                                stroke={
                                  pathname === link.href
                                    ? "#f06605"
                                    : "var(--primary)"
                                }
                              />
                              <span>{link.name}</span>
                            </div>
                          </NavLink>
                        ))}
                      </>
                    )}
                  </>
                )
              )}
            </div>
            {checkIfRDPMSApp() && (
              <div className="layout_annotation-tool-wrapper">
                <button className="annotaion-btn" onClick={openAnnotaionTool}>
                  Annotation Tool <NewTab />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      {menuOpen && (
        <div
          className="layout_sidenav_overlay"
          onClick={() => {
            setMenuOpen(false);
            setNavExpanded({});
          }}
        ></div>
      )}

      <div
        // className={`h100 flex col layout_container ${
        //   menuOpen ? "layout_mtransition" : ""
        // }`}
        className={`layout_container `}
      >
        <header className={`flex al-ctr layout_header`}>
          <nav className={`h100 flex al-ctr`}>
            <ul className={`h100 flex al-ctr layout_navlist`}>
              <li className={`flex al-ctr del_nav-and-companyname`}>
                {
                  <button
                    type="button"
                    id="sideNavToggle"
                    className={`icon-btn layout_menuBtn ${
                      home && "layout_hideMenu"
                    }`}
                    onClick={toggleSideMenu}
                  >
                    <Menu fill="grey" />
                  </button>
                }
                {/* <span className="layout_company">AVT Inc.</span> */}
                <span className="layout_company">
                  {localStorage.getItem("companyName") || ""}
                </span>
              </li>
              <li>
                <Link
                  to="/platform"
                  className="layout_navItem layout_company-logo-wrapper"
                  onClick={() => {
                    setMenuOpen(false);
                    setNavExpanded({});
                  }}
                  id="platformRoot"
                >
                  <img
                    //src={data?.Logo}
                    //src={companyDetails?.Logo}
                    src={localStorage.getItem("logo") || ""}
                    className="layout_logo layout_company-logo"
                    alt="Company Logo"
                  ></img>
                </Link>
              </li>
            </ul>
          </nav>
          {JSON.parse(localStorage.getItem("IncludeDelpheonLogo")) && (
            <img
              src={DelpheonLogo}
              className="delpheon_logo"
              alt="Delpheon Logo"
            ></img>
          )}
          <div className="layout_profile">
            <span>
              <span className="layout_wel">Welcome, </span>
              <strong className="layout_username">{username}</strong>
            </span>
            <span>
              <DropDownMenu>
                <UserFilled
                  width={21}
                  height={21}
                  fill="grey"
                  className="layout_userIcon"
                />
              </DropDownMenu>
            </span>
            <span className="layout_separator"></span>
            <button
              type="button"
              id="logoutButton"
              className={`icon-btn`}
              title="Logout"
              style={{ verticalAlign: "middle" }}
              onClick={() => setLogoutModal(true)}
            >
              <Logout fill="grey" />
            </button>
          </div>
        </header>
        <Modal
          isOpen={logoutModal}
          heading="Logout"
          closeModal={() => setLogoutModal(false)}
          primaryActionName="Yes"
          onSubmit={onLogout}
        >
          <div className="txt-center">Are you sure you want to logout?</div>
        </Modal>
        {pathname !== "/" &&
          !routes.some(
            (r) =>
              (pathname.indexOf("/", 1) === -1
                ? pathname.slice(1)
                : pathname.slice(1, pathname.indexOf("/", 1))) === r
          ) && <NotFound />}
      </div>
    </div>
  );
}
