import { useInterval } from "./useInterval";
import useAuth from "./useAuth";
import useUpdateEffect from "./useUpdateEffect";
import { delApi } from "../delApi";

import { useMemo } from "react";

function calcRefreshPoint(exp) {
  const timeLeft = exp * 1000 - new Date().getTime();

  const delay = timeLeft - 30 * 1000 > 5000 ? timeLeft - 30 * 1000 : 5000;

  console.log("delay:", delay);
  return delay;
}

export function useRefreshToken() {
  const { setAuth, exp, isAuthenticated, logout } = useAuth();

  const delay = useMemo(() => calcRefreshPoint(exp), [exp]);

  useInterval(
    () => {
      const callDelApi = async () => {
        const refreshtoken = localStorage.getItem("refresh_token");
        const realmName = localStorage.getItem("realmName");
        console.log(refreshtoken);
        const resp = await delApi(
          "/admin/api/authentication/refreshtoken",
          "POST",
          { refreshtoken, realmName },
          null,
          null,
          { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
        );
        const prevRefreshToken = localStorage.getItem("refresh_token");
        console.log(prevRefreshToken);
        if (resp && prevRefreshToken) {
          localStorage.setItem("access_token", resp.access_token);
          localStorage.setItem("refresh_token", resp.refresh_token);
          setAuth((o) => ({
            ...o,
            token: resp.access_token,
            refreshtoken: resp.refresh_token,
          }));

          // Dispatch this event so that the WM app is aware about the Token change
          var event = new CustomEvent("RefreshTokenEvent", {
            bubbles: true,
          });
          document.body.dispatchEvent(event);
        }
      };
      if (exp * 1000 - new Date().getTime() > 0) {
        callDelApi();
      } else {
        logout(true);
      }
    },
    isAuthenticated && exp ? delay : null
  );

  //   useUpdateEffect(() => {
  //     if (refTokRes) {
  //       console.log(`refreshing token`);
  //       localStorage.setItem("access_token", refTokRes.access_token);
  //       setAuth((o) => ({ ...o, token: refTokRes.access_token }));
  //     }
  //   }, [refTokRes]);
}
